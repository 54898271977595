import React, { useEffect, useState,useRef } from 'react'
import { Container, Card, Button, Row, Col ,Form, Modal} from 'react-bootstrap'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Sidebar from './sidebar';
import upload from '../assests/upload.svg'
import axios from 'axios';
import DOMPurify from 'dompurify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import html2pdf from 'html2pdf.js';
import Invoice from './invoice2';
// Import the Toastify styles
import Select from 'react-select';
function User() {
    // const [mobile, setMobile] = useState('');
    const [error, setMobileError] = useState('');
    const [passportError, setPassportError] = useState('');
    const [addressError, setAddressError] = useState('');
    const navigate = useNavigate()
    const invoiceRef = useRef(null);
    const admin_id = sessionStorage.getItem('id')
    const [form, setForm] = useState({})
    const [emailing, setEmails] = useState('')
    const [maxDate, setMaxDate] = useState('');
    const [invoiceid, setInvoiceid] = useState('')
    const [display, setDisplay] = useState('display-none')
    const [displayfrom, setDisplayfrom] = useState('display-none')
    const [displayat, setDisplayat] = useState('display-none')
    const [displaycountry1, setDisplaycountry1] = useState('display-none')
    const [displaycountry2, setDisplaycountry2] = useState('display-none')
    const [displaycountry3, setDisplaycountry3] = useState('display-none')
    const [displaytobepaid, setDisplaytobepaid] = useState('display-none')
    const [displayrow, setDisplayrow] = useState('display-none')
    const [country, setCountry] = useState([])
    const [displaypaidon, setDisplaypaidon] = useState('display-none')
    const [displaypaydate, setDisplaypaydate] = useState('display-none')
    const [paymentdate, setpaymentdate] = useState('Date Of Payment')
    const [displayuip, setDisplayuip] = useState("display-none")
    const [displayamount, setdisplayamount] = useState("display-none")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [emailError, setEmailError] = useState('');


    const [rows, setRows] = useState([{ id: 1 }]);

    const handleAddRow = (e) => {
        e.preventDefault()

        if (rows.length <= 4) {
            setRows([...rows, { id: rows.length + 1 }]);
        }
        setDisplayrow('display-none') 
    };


    // const countryOptions = country.map((item) => ({
    //     value: item.country,
    //     label: item.country
    // }));
    const options = [
        { value: 'Usa', label: 'Usa' },
        { value: 'Australia', label: 'Australia' },
        { value: 'Canada', label: 'Canada' },
        { value: 'India', label: 'India' },
        { value: 'Japan', label: 'Japan' },
        { value: 'New Zealand', label: 'New Zealand' },
        { value: 'South Korea', label: 'South Korea' },
        { value: 'Uk', label: 'Uk' },

        { value: 'Åland Islands', label: 'Åland Islands' },
        { value: 'Albania', label: 'Albania' },
        { value: 'Algeria', label: 'Algeria' },
        { value: 'American Samoa', label: 'American Samoa' },
        { value: 'Andorra', label: 'Andorra' },
        { value: 'Angola', label: 'Angola' },
        { value: 'Anguilla', label: 'Anguilla' },
        { value: 'Antarctica', label: 'Antarctica' },
        { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
        { value: 'Argentina', label: 'Argentina' },
        { value: 'Armenia', label: 'Armenia' },
        { value: 'Aruba', label: 'Aruba' },
        { value: 'Australia', label: 'Australia' },
        { value: 'Austria', label: 'Austria' },
        { value: 'Azerbaijan', label: 'Azerbaijan' },
        { value: 'Bahamas', label: 'Bahamas' },
        { value: 'Bahrain', label: 'Bahrain' },
        { value: 'Bangladesh', label: 'Bangladesh' },
        { value: 'Barbados', label: 'Barbados' },
        { value: 'Belarus', label: 'Belarus' },
        { value: 'Belgium', label: 'Belgium' },
        { value: 'Belize', label: 'Belize' },
        { value: 'Benin', label: 'Benin' },
        { value: 'Bermuda', label: 'Bermuda' },
        { value: 'Bhutan', label: 'Bhutan' },
        { value: 'Bolivia', label: 'Bolivia' },
        { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
        { value: 'Botswana', label: 'Botswana' },
        { value: 'Bouvet Island', label: 'Bouvet Island' },
        { value: 'Brazil', label: 'Brazil' },
        { value: 'British Indian Ocean Territory', label: 'British Indian Ocean Territory' },
        { value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
        { value: 'Bulgaria', label: 'Bulgaria' },
        { value: 'Burkina Faso', label: 'Burkina Faso' },
        { value: 'Burundi', label: 'Burundi' },
        { value: 'Cambodia', label: 'Cambodia' },
        { value: 'Cameroon', label: 'Cameroon' },
        { value: 'Canada', label: 'Canada' },
        { value: 'Cape Verde', label: 'Cape Verde' },
        { value: 'Cayman Islands', label: 'Cayman Islands' },
        { value: 'Central African Republic', label: 'Central African Republic' },
        { value: 'Chad', label: 'Chad' },
        { value: 'Chile', label: 'Chile' },
        { value: 'China', label: 'China' },
        { value: 'Christmas Island', label: 'Christmas Island' },
        { value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
        { value: 'Colombia', label: 'Colombia' },
        { value: 'Comoros', label: 'Comoros' },
        { value: 'Congo', label: 'Congo' },
        { value: 'Congo, The Democratic Republic of The', label: 'Congo, The Democratic Republic of The' },
        { value: 'Cook Islands', label: 'Cook Islands' },
        { value: 'Costa Rica', label: 'Costa Rica' },
        { value: 'Cote D\'ivoire', label: 'Cote D\'ivoire' },
        { value: 'Croatia', label: 'Croatia' },
        { value: 'Cuba', label: 'Cuba' },
        { value: 'Cyprus', label: 'Cyprus' },
        { value: 'Czech Republic', label: 'Czech Republic' },
        { value: 'Denmark', label: 'Denmark' },
        { value: 'Djibouti', label: 'Djibouti' },
        { value: 'Dominica', label: 'Dominica' },
        { value: 'Dominican Republic', label: 'Dominican Republic' },
        { value: 'Ecuador', label: 'Ecuador' },
        { value: 'Egypt', label: 'Egypt' },
        { value: 'El Salvador', label: 'El Salvador' },
        { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
        { value: 'Eritrea', label: 'Eritrea' },
        { value: 'Estonia', label: 'Estonia' },
        { value: 'Ethiopia', label: 'Ethiopia' },
        { value: 'Falkland Islands (Malvinas)', label: 'Falkland Islands (Malvinas)' },
        { value: 'Faroe Islands', label: 'Faroe Islands' },
        { value: 'Fiji', label: 'Fiji' },
        { value: 'Finland', label: 'Finland' },
        { value: 'France', label: 'France' },
        { value: 'French Guiana', label: 'French Guiana' },
        { value: 'French Polynesia', label: 'French Polynesia' },
        { value: 'French Southern Territories', label: 'French Southern Territories' },
        { value: 'Gabon', label: 'Gabon' },
        { value: 'Gambia', label: 'Gambia' },
        { value: 'Georgia', label: 'Georgia' },
        { value: 'Germany', label: 'Germany' },
        { value: 'Ghana', label: 'Ghana' },
        { value: 'Gibraltar', label: 'Gibraltar' },
        { value: 'Greece', label: 'Greece' },
        { value: 'Greenland', label: 'Greenland' },
        { value: 'Grenada', label: 'Grenada' },
        { value: 'Guadeloupe', label: 'Guadeloupe' },
        { value: 'Guam', label: 'Guam' },
        { value: 'Guatemala', label: 'Guatemala' },
        { value: 'Guernsey', label: 'Guernsey' },
        { value: 'Guinea', label: 'Guinea' },
        { value: 'Guinea-bissau', label: 'Guinea-bissau' },
        { value: 'Guyana', label: 'Guyana' },
        { value: 'Haiti', label: 'Haiti' },
        { value: 'Heard Island and Mcdonald Islands', label: 'Heard Island and Mcdonald Islands' },
        { value: 'Holy See (Vatican City State)', label: 'Holy See (Vatican City State)' },
        { value: 'Honduras', label: 'Honduras' },
        { value: 'Hong Kong', label: 'Hong Kong' },
         { value: 'Hungary', label: 'Hungary' },
        { value: 'Iceland', label: 'Iceland' },
        { value: 'India', label: 'India' },
        { value: 'Indonesia', label: 'Indonesia' },
        { value: 'Iran, Islamic Republic of', label: 'Iran, Islamic Republic of' },
        { value: 'Iraq', label: 'Iraq' },
        { value: 'Ireland', label: 'Ireland' },
        { value: 'Isle of Man', label: 'Isle of Man' },
        { value: 'Israel', label: 'Israel' },
        { value: 'Italy', label: 'Italy' },
        { value: 'Jamaica', label: 'Jamaica' },
        { value: 'Japan', label: 'Japan' },
        { value: 'Jersey', label: 'Jersey' },
        { value: 'Jordan', label: 'Jordan' },
        { value: 'Kazakhstan', label: 'Kazakhstan' },
        { value: 'Kenya', label: 'Kenya' },
        { value: 'Kiribati', label: 'Kiribati' },
        { value: 'Korea, Democratic People\'s Republic of', label: 'Korea, Democratic People\'s Republic of' },
        { value: 'Korea, Republic of', label: 'Korea, Republic of' },
        { value: 'Kuwait', label: 'Kuwait' },
        { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
        { value: 'Lao People\'s Democratic Republic', label: 'Lao People\'s Democratic Republic' },
        { value: 'Latvia', label: 'Latvia' },
        { value: 'Lebanon', label: 'Lebanon' },
        { value: 'Lesotho', label: 'Lesotho' },
        { value: 'Liberia', label: 'Liberia' },
        { value: 'Libyan Arab Jamahiriya', label: 'Libyan Arab Jamahiriya' },
        { value: 'Liechtenstein', label: 'Liechtenstein' },
        { value: 'Lithuania', label: 'Lithuania' },
        { value: 'Luxembourg', label: 'Luxembourg' },
        { value: 'Macao', label: 'Macao' },
        { value: 'Macedonia, The Former Yugoslav Republic of', label: 'Macedonia, The Former Yugoslav Republic of' },
        { value: 'Madagascar', label: 'Madagascar' },
        { value: 'Malawi', label: 'Malawi' },
        { value: 'Malaysia', label: 'Malaysia' },
        { value: 'Maldives', label: 'Maldives' },
        { value: 'Mali', label: 'Mali' },
        { value: 'Malta', label: 'Malta' },
        { value: 'Marshall Islands', label: 'Marshall Islands' },
        { value: 'Martinique', label: 'Martinique' },
        { value: 'Mauritania', label: 'Mauritania' },
        { value: 'Mauritius', label: 'Mauritius' },
        { value: 'Mayotte', label: 'Mayotte' },
        { value: 'Mexico', label: 'Mexico' },
        { value: 'Micronesia, Federated States of', label: 'Micronesia, Federated States of' },
        { value: 'Moldova, Republic of', label: 'Moldova, Republic of' },
        { value: 'Monaco', label: 'Monaco' },
        { value: 'Mongolia', label: 'Mongolia' },
        { value: 'Montenegro', label: 'Montenegro' },
        { value: 'Montserrat', label: 'Montserrat' },
        { value: 'Morocco', label: 'Morocco' },
        { value: 'Mozambique', label: 'Mozambique' },
        { value: 'Myanmar', label: 'Myanmar' },
        { value: 'Namibia', label: 'Namibia' },
        { value: 'Nauru', label: 'Nauru' },
        { value: 'Nepal', label: 'Nepal' },
        { value: 'Netherlands', label: 'Netherlands' },
        { value: 'Netherlands Antilles', label: 'Netherlands Antilles' },
        { value: 'New Caledonia', label: 'New Caledonia' },
        { value: 'New Zealand', label: 'New Zealand' },
        { value: 'Nicaragua', label: 'Nicaragua' },
        { value: 'Niger', label: 'Niger' },
        { value: 'Nigeria', label: 'Nigeria' },
        { value: 'Niue', label: 'Niue' },
        { value: 'Norfolk Island', label: 'Norfolk Island' },
        { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
        { value: 'Norway', label: 'Norway' },
        { value: 'Oman', label: 'Oman' },
        { value: 'Pakistan', label: 'Pakistan' },
        { value: 'Palau', label: 'Palau' },
        { value: 'Palestinian Territory, Occupied', label: 'Palestinian Territory, Occupied' },
        { value: 'Panama', label: 'Panama' },
        { value: 'Papua New Guinea', label: 'Papua New Guinea' },
        { value: 'Paraguay', label: 'Paraguay' },
        { value: 'Peru', label: 'Peru' },
        { value: 'Philippines', label: 'Philippines' },
        { value: 'Pitcairn', label: 'Pitcairn' },
        { value: 'Poland', label: 'Poland' },
        { value: 'Portugal', label: 'Portugal' },
        { value: 'Puerto Rico', label: 'Puerto Rico' },
        { value: 'Qatar', label: 'Qatar' },
        { value: 'Reunion', label: 'Reunion' },
        { value: 'Romania', label: 'Romania' },
        { value: 'Russian Federation', label: 'Russian Federation' },
        { value: 'Rwanda', label: 'Rwanda' },
        { value: 'Saint Helena', label: 'Saint Helena' },
        { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
        { value: 'Saint Lucia', label: 'Saint Lucia' },
        { value: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
        { value: 'Saint Vincent and The Grenadines', label: 'Saint Vincent and The Grenadines' },
        { value: 'Samoa', label: 'Samoa' },
        { value: 'San Marino', label: 'San Marino' },
        { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
        { value: 'Saudi Arabia', label: 'Saudi Arabia' },
        { value: 'Senegal', label: 'Senegal' },
        { value: 'Serbia', label: 'Serbia' },
        { value: 'Seychelles', label: 'Seychelles' },
        { value: 'Sierra Leone', label: 'Sierra Leone' },
        { value: 'Singapore', label: 'Singapore' },
        { value: 'Slovakia', label: 'Slovakia' },
        { value: 'Slovenia', label: 'Slovenia' },
        { value: 'Solomon Islands', label: 'Solomon Islands' },
        { value: 'Somalia', label: 'Somalia' },
        { value: 'South Africa', label: 'South Africa' },
        { value: 'South Georgia and The South Sandwich Islands', label: 'South Georgia and The South Sandwich Islands' },
        { value: 'Spain', label: 'Spain' },
        { value: 'Sri Lanka', label: 'Sri Lanka' },
        { value: 'Sudan', label: 'Sudan' },
        { value: 'Suriname', label: 'Suriname' },
        { value: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
        { value: 'Swaziland', label: 'Swaziland' },
        { value: 'Sweden', label: 'Sweden' },
        { value: 'Switzerland', label: 'Switzerland' },
        { value: 'Syrian Arab Republic', label: 'Syrian Arab Republic' },
        { value: 'Taiwan', label: 'Taiwan' },
        { value: 'Tajikistan', label: 'Tajikistan' },
        { value: 'Tanzania, United Republic of', label: 'Tanzania, United Republic of' },
        { value: 'Thailand', label: 'Thailand' },
        { value: 'Timor-leste', label: 'Timor-leste' },
        { value: 'Togo', label: 'Togo' },
        { value: 'Tokelau', label: 'Tokelau' },
        { value: 'Tonga', label: 'Tonga' },
        { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
        { value: 'Tunisia', label: 'Tunisia' },
        { value: 'Turkey', label: 'Turkey' },
        { value: 'Turkmenistan', label: 'Turkmenistan' },
        { value: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
        { value: 'Tuvalu', label: 'Tuvalu' },
        { value: 'Uganda', label: 'Uganda' },
        { value: 'Ukraine', label: 'Ukraine' },
        { value: 'United Arab Emirates', label: 'United Arab Emirates' },
        { value: 'United Kingdom', label: 'United Kingdom' },
        { value: 'United States', label: 'United States' },
        { value: 'United States Minor Outlying Islands', label: 'United States Minor Outlying Islands' },
        { value: 'Uruguay', label: 'Uruguay' },
        { value: 'Uzbekistan', label: 'Uzbekistan' },
        { value: 'Vanuatu', label: 'Vanuatu' },
        { value: 'Venezuela', label: 'Venezuela' },
        { value: 'Viet Nam', label: 'Viet Nam' },
        { value: 'Virgin Islands, British', label: 'Virgin Islands, British' },
        { value: 'Virgin Islands, U.S.', label: 'Virgin Islands, U.S.' },
        { value: 'Wallis and Futuna', label: 'Wallis and Futuna' },
        { value: 'Western Sahara', label: 'Western Sahara' },
        { value: 'Yemen', label: 'Yemen' },
        { value: 'Zambia', label: 'Zambia' },
        { value: 'Zimbabwe', label: 'Zimbabwe' }
    ];

    const handleinput = (e, actionMeta) => {
        let name, value;

        // Check if it's a React Select input (React Select passes value and actionMeta instead of event)
        if (actionMeta) {
            name = actionMeta.name; // Get the name from actionMeta
            value = e ? e.value : ''; // The value is provided by the select
        } else {
            // Regular HTML input
            const { target } = e;
            name = target.name;
            value = target.value;
        }
        if (name === 'address') {
            if (value.length < 50) {
                setAddressError('Address must be at least 50 characters long.');
            } else {
                setAddressError('');
            }
        }
        if (name === 'email') {
            const allowedDomains = ["hotmail.com", "gmail.com", "yahoo.com","yahoo.co.in","outlook.com"];
            const emailParts = value.split('@');
            if (emailParts.length > 1) {
                const domain = emailParts[1];
                if (!allowedDomains.includes(domain)) {
                    // Set error message and stop further form update
                    setEmailError("Please enter a valid email address");
                } else {
                    // Clear error message if email is valid
                    setEmailError('');
                }
            } else {
                setEmailError("Please enter a valid email.");
            }
        }

        // Mobile number validation logic
        if (name === 'mobile') {
            const firstDigit = value.charAt(0);
            // Check if the number starts between 6-9 or is not exactly 10 digits
            if (!/^[6-9]/.test(firstDigit) || value.length !== 10) {
                setMobileError("Please enter a valid mobile number");
            } else {
                setMobileError('');
            }
        }

        // Passport validation
        if (name === 'passport') {
            // Regex to check if the passport starts with a capital letter followed by exactly six digits
            const passportPattern = /^[A-Z][0-9]{6}$/;
            if (!passportPattern.test(value)) {
                setPassportError('Passport must start with a capital letter followed by 6 digits.');
            } else {
                setPassportError('');
            }
        }
        if (name === 'modeofpayment' && value === 'Cheque' || name === 'modeofpayment' && value === 'Draft' ) {
            console.log("display block")
            setDisplay('display-block')
            setDisplayat('display-block')
            setDisplayfrom('display-none')
            setDisplaypaydate('display-block')
            setpaymentdate('Cheque/Draft Dated')
        }
        if (name === 'modeofpayment' && value === 'Bank Transfer') {
            setDisplay('display-none')
            setDisplayfrom('display-block')
            setDisplayat('display-none')
            setDisplaypaydate('display-block')
            setpaymentdate('Date Of Payment')
            setDisplayuip('display-none')
        }
        if (name === 'modeofpayment' && value === 'Cash') {
            setDisplayat('display-none')
            setDisplay('display-none')
            setDisplayat('display-none')
            setDisplayfrom('display-none')
            setDisplaypaydate('display-none')
            setDisplayuip('display-none')
        }
        if (name === 'modeofpayment' && value === 'UPI') {
            setDisplayuip('display-block')
            setDisplayat('display-none')
            setDisplay('display-none')
            setDisplayat('display-none')
            setDisplayfrom('display-none')
            setDisplaypaydate('display-block')
            setpaymentdate('Date Of Payment')
            // setDisplaypaydate('display-none')
        }
        if (name === 'modeofpayment' && value === 'Net Banking') {
            setDisplay('display-none')
            setDisplayfrom('display-block')
            setDisplayat('display-none')
            setDisplaypaydate('display-block')
            setpaymentdate('Date Of Payment')
            setDisplayuip('display-none')
        }
        if (name === 'services' && value === 'Student Visa') {
            setDisplaycountry1('display-block')
            setDisplaycountry2('display-none')
            setDisplaycountry3('display-none')

        }
        if (name === 'services' && value === 'Immigrant Visa') {
            setDisplaycountry3('display-block')
            setDisplaycountry2('display-none')
            setDisplaycountry1('display-none')
        }
        if (name === 'services' && value === 'Tourist Visa') {
            setDisplaycountry2('display-block')
            setDisplaycountry1('display-none')
            setDisplaycountry3('display-none')
        }
        if (name === 'services' && value === 'Applying U.S Passport (New Born)' || name === 'services' && value === 'Applying U.S Passport (Lost)' || name === 'services' && value === 'Visa Conversion Within USA' || name === 'services' && value === 'Tour packages (customized)' ){
            setDisplaycountry1('display-none')
            setDisplaycountry2('display-none')
            setDisplaycountry3('display-none')
        }
        if (name === 'painon') {
            if (value === 'ondate') {
                setDisplaypaidon('display-block')
            } else {
                setDisplaypaidon('display-none')
            }
        } 
        if (name === 'pending_bal') {
            if (value === '' || value === '0') {
                setDisplaytobepaid('display-none');
            } else {
                setDisplaytobepaid('display-block');
            }
        }
     
        if (name === 'amount') {
            if (value === 'above') {
                
                setdisplayamount('display-block')
            } else {
                setdisplayamount('display-none') 
            }
        }
        if (name === 'comment_1') {
            if (value != '') {
                setDisplayrow('display-block')
            } else {
                setDisplayrow('display-none') 
            }
        }
        if (name === 'comment_2') {
            if (value != '') {
                setDisplayrow('display-block')
            } else {
                setDisplayrow('display-none') 
            }
        }
        if (name === 'comment_3') {
            if (value != '') {
                setDisplayrow('display-block')
            } else {
                setDisplayrow('display-none') 
            }
        }
        if (name === 'comment_4') {
            if (value != '') {
                setDisplayrow('display-block')
            } else {
                setDisplayrow('display-none') 
            }
        }
     
        
        const sanitizedValue = DOMPurify.sanitize(value);
        setForm({ ...form, [name]: sanitizedValue });
        
     


    }


    const handlesubmit = async (e) => {
        e.preventDefault();
       
        try {
            // Step 1: Submit the form and get the response
            const response = await axios.post('https://invoice.pksinfotechpltd.com/backend/public/addinvoice', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data === "error") {
                alert("error");
            } else {

                
                setEmails(form.email)
                setInvoiceid(response.data);  
                setIsModalOpen(true);      

                
                // Step 2: Generate the PDF
                // const pdfBlob = await generatePDF();  
                // await sendPDFByEmail(pdfBlob,form.email);
                setTimeout(async () => {
                    const pdfBlob = await generatePDF();
                    console.log("PDF Blob:", pdfBlob); // Check if this returns a Blob

                    if (pdfBlob instanceof Blob) {
                        await sendPDFByEmail(pdfBlob, form.email);
                    } else {
                        console.error('generatePDF did not return a Blob');
                    }
                }, 2000);

            }
        } catch (error) {
            console.error('Error:', error);
        }
  
    };


    const generatePDF = () => {
        const element = invoiceRef.current;
        const img = document.getElementById('invoiceImage'); 
        if (!element) {
            console.error('Invoice element not found.');
            return;
        }
    
        if (!img.complete) {
            
            img.onload = () => {
                convertToPDF(element); 
            };
        } else {
    
            convertToPDF(element);
        }

        const element2 = document.getElementById('targetLR');
        if (!element) {
            console.error('Element with id "targetLR" not found.');
            return;
        }

    };

    const convertToPDF = (element) => {
        const opt = {
            margin: 0.1, 
            filename: 'LR.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 3, logging: true }, 
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait', pageBreak: { mode: 'avoid-all' } }, 
            pagebreak: { mode: 'avoid-all' } 
        };

     
        html2pdf().from(element).set(opt).output('blob').then((pdfBlob) => {
            sendPDFByEmail(pdfBlob,form.email);
        });
        // html2pdf().from(element).set(opt).save();
    };
    const generatePDF2 = () => {
        const element = invoiceRef.current;
        const img = document.getElementById('invoiceImage'); 
        if (!element) {
            console.error('Invoice element not found.');
            return;
        }
    
        if (!img.complete) {
            
            img.onload = () => {
                convertToPDF2(element); 
            };
        } else {
    
            convertToPDF2(element);
        }

        const element2 = document.getElementById('targetLR');
        if (!element) {
            console.error('Element with id "targetLR" not found.');
            return;
        }

    };

    const convertToPDF2 = (element) => {
        const opt = {
            margin: 0.1, 
            filename: 'LR.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 3, logging: true }, 
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait', pageBreak: { mode: 'avoid-all' } }, 
            pagebreak: { mode: 'avoid-all' } 
        };

     
        // html2pdf().from(element).set(opt).output('blob').then((pdfBlob) => {
        //     sendPDFByEmail(pdfBlob,form.email);
        // });
        html2pdf().from(element).set(opt).save();
    };
    const generatePDF3= () => {
        const element = invoiceRef.current;
        const img = document.getElementById('invoiceImage');

        if (!element) {
            console.error('Invoice element not found.');
            return;
        }

        if (!img.complete) {
            img.onload = () => {
                convertToPDF3(element);
            };
        } else {
            convertToPDF3(element);
        }

        const element2 = document.getElementById('targetLR');
        if (!element2) {
            console.error('Element with id "targetLR" not found.');
            return;
        }
    };

    const convertToPDF3 = (element) => {
        const opt = {
            margin: 0.1,
            filename: 'LR.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 3, logging: true },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait', pageBreak: { mode: 'avoid-all' } },
            pagebreak: { mode: 'avoid-all' }
        };

        // Generate PDF as a Blob
        html2pdf().from(element).set(opt).output('blob').then((pdfBlob) => {
            // Create a URL for the Blob
            const url = URL.createObjectURL(pdfBlob);

            // Open the URL in a new window
            const printWindow = window.open(url, '_blank');

            if (printWindow) {
                // Wait for the PDF to load and then open the print dialog
                printWindow.onload = () => {
                    printWindow.print();
                };
            } else {
                console.error('Failed to open print window.');
            }
        });
    };

        const sendPDFByEmail = (pdfBlob,msg) => {
            console.log('sendemailmsg',msg)
            const formData = new FormData();
            formData.append('email', msg);  
            formData.append('pdf', pdfBlob, 'invoice.pdf'); 
            return axios.post('https://invoice.pksinfotechpltd.com/backend/public/sendemail', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        };
 


    const Date_modify = () => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setMaxDate(formattedDate);
    }

    const onClose = () => {

        setIsModalOpen(false);

    }
    useEffect(() => {
        Date_modify();  
    },[])
    return (
        <div>
 
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <ToastContainer />
                <div className='m-0 p-0 w-100'>
                    
                    <Container fluid className='d-flex justify-content-between px-5 mt-4'>
                    
                        <h1 className='text-blue fw-6'>Payment Invoice</h1>
                    </Container>
                    <Card className=' mx-5 mt-4 p-4 pb-4 shadow shadow-md '  >
                        <form onSubmit={handlesubmit} >
                            <Row className='g-4'>
                                <Col md={12}>
                                        <div className="d-flex justify-content-between px-0">
                                            <h3 className='text-blue fw-6 mb-3'>Basic Detail</h3>
                                            <div className="d-flex">
                                            <NavLink to='/' className='btn bg-danger fs-5 fw-6 text-white me-3 h-75' >Cancel</NavLink>
                                            <Button onClick={handlesubmit}  className='btn bg-blue fs-5 fw-6 text-white  h-75' >Submit</Button>
                                            </div>
                                            
                                        </div>
                                    <Row className='px-3 g-2 overflowy' style={{ maxHeight: '72vh', minHeight: '72vh' }}>
                                       
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6'>Name <span className='text-danger'>*</span></h6>
                                            <input type="text" className='form-control mb-2' placeholder='Enter Name' name="name" onChange={handleinput} required />
                                        </Col>
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6'>Father's Name <span className='text-danger'>*</span></h6>
                                            <input type="text" className='form-control  mb-2' placeholder='Father Name' name="fname" onChange={handleinput} required />
                                        </Col>
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6'>DOB <span className='text-danger'>*</span></h6>
                                            <input type="date" className='form-control  mb-2' placeholder='Enter DOB' name="dob" max={maxDate} onChange={handleinput} required />
                                        </Col>
                                        <Col md={6}>
                                        <h6 className='text-blue fw-6'>
                                            Passport No <span className='text-danger'>*</span>
                                        </h6>
                                        <input
                                            type="text"
                                            className={`form-control mb-2 ${passportError ? 'border-danger' : ''}`}
                                            placeholder='Enter Passport No'
                                            name="passport"
                                            onChange={handleinput}
                                            required
                                        />
                                        {passportError && <span className="text-danger">{passportError}</span>}
                                </Col>
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6'>Email<span className='text-danger'>*</span></h6>
                                            {/* <input type="email" className='form-control mb-3' placeholder='Enter Email' name="email" onChange={handleinput} required /> */}
                                            <input
                                                type="email"
                                                className={`form-control mb-2 ${emailError ? 'is-invalid' : ''}`} // Conditional class for error
                                                placeholder='Enter Email'
                                                name="email"
                                                onChange={handleinput}
                                                required
                                            />
                                            {emailError && <small className="text-danger">{emailError}</small>} {/* Display error message */}
                                        </Col>
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6 '>Mobile No<span className='text-danger'>*</span></h6>
                                            {/* <input type="number" className='form-control mb-2' placeholder='Enter Mobile No' name="mobile" onChange={handleinput} required /> */}
                                            <input
                                                type="number"
                                                className={`form-control mb-2 ${error ? 'border-danger' : ''}`}
                                                placeholder='Enter Mobile No'
                                                name="mobile"
                                                // value=
                                                onChange={handleinput}
                                                required
                                            />
                                            {error && <small className='text-danger'>{error}</small>}
                                        </Col>
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6'>On Account Of<span className='text-danger'>*</span></h6>
                                            <Form.Select className="rounded-4 mb-2" name="services" onChange={handleinput} required>
                                                <option value="" selected>Select Service</option>
                                                <option value="Student Visa">Student Visa</option>
                                                <option value="Tourist Visa">Tourist Visa</option>
                                                <option value="Immigrant Visa">Immigrant Visa</option>
                                                <option value="Applying U.S Passport (New Born)">Applying U.S Passport (New Born)</option>
                                                <option value="Applying U.S Passport (Lost)">Applying U.S Passport (Lost)</option>
                                                <option value="Visa Conversion Within USA">Visa Conversion Within USA</option>
                                                <option value="Tour packages (customized)">Tour packages (customized)</option>
                                              
                                            </Form.Select>
                                        </Col>
                                        <Col md={6} className={`${displaycountry1}`}>
                                            <h6 className='text-blue fw-6'>Country</h6>
                                            <Form.Select className="rounded-4 mb-2" name="country" onChange={handleinput}>
                                                <option value="" selected>Select Country</option>
                                                <option value="USA">USA</option>
                                                <hr />  
                                                <option value="AUSTRALIA">AUSTRALIA</option>
                                                <option value="CANADA">CANADA</option>
                                                {/* <option value="INDIA">INDIA</option> */}
                                                <option value="JAPAN">JAPAN</option>
                                                <option value="NEW ZEALAND">NEW ZEALAND</option>
                                                <option value="SCHENGEN">SCHENGEN</option>
                                                <option value="SOUTH KOREA">SOUTH KOREA</option>
                                                <option value="UK">UK</option>
                                                <option value="USA">USA</option>
                                              
                                            </Form.Select>
                                        </Col>
                                        <Col md={6} className={`${displaycountry3}`}>
                                            <h6 className='text-blue fw-6 mb-2'>Country</h6>
                                            <Form.Select className="rounded-4" name="country" onChange={handleinput}>
                                                <option value="" selected>Select Country</option>
                                                <option value="USA">USA</option>
                                                <hr />  
                                                <option value="CANADA">CANADA</option>
                                                {/* <option value="INDIA">INDIA</option> */}
                                                <option value="JAPAN">JAPAN</option>
                                                <option value="SCHENGEN">SCHENGEN</option>
                                                <option value="SOUTH KOREA">SOUTH KOREA</option>
                                                <option value="UK">UK</option>
                                                <option value="USA">USA</option>
                                              
                                            </Form.Select>
                                        </Col>
                                        <Col md={6} className={`${displaycountry2}`}>
                                            <h6 className='text-blue fw-6 mb-2'>Country</h6>
                                         
                                            <Select
                                                className="rounded-4"
                                                options={options}
                                                onChange={handleinput}
                                                placeholder="Select a country"
                                                name='country'
                                                isSearchable
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6' >Amount<span className='text-danger'>*</span></h6>
                                            {/* <input type="number" className='form-control mb-3' placeholder='Enter Amount' name="amount" onChange={handleinput} required /> */}
                                            <Form.Select className="rounded-4 mb-2" name="amount" onChange={handleinput} required>
                                                <option value="" selected>Select Amount</option>
                                                <option value="5000">5000</option>
                                                <option value="10000">10000</option>
                                                <option value="15000">15000</option>
                                                <option value="20000">20000</option>
                                                <option value="25000">25000</option>
                                                <option value="30000">30000</option>
                                                <option value="35000">35000</option>
                                                <option value="40000">40000</option>
                                                <option value="45000">45000</option>
                                                <option value="50000">50000</option>
                                                <option value="above">above</option>
                                            </Form.Select>
                                        </Col>
                                     
                                        <Col md={6} className={`${displayamount}`}>
                                            <h6 className='text-blue fw-6' >Enter Amount Manually<span className='text-danger'>*</span></h6>
                                            <input type="number" className='form-control mb-2' placeholder='Enter Amount' name="amount2" onChange={handleinput}  />
                                       
                                        </Col> 
                                     
                                    
                                 
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6'>Mode Of Payment<span className='text-danger'>*</span> </h6>
                                            <Form.Select className="rounded-4 mb-2" name="modeofpayment" onChange={handleinput} required>
                                                <option value="" selected>Select Service</option>
                                                <option value="Cash">By Cash</option>
                                                <option value="Cheque">By Cheque</option>
                                                <option value="Draft">By Draft</option>
                                                <option value="Bank Transfer">By Bank Transfer</option>
                                                <option value="UPI">By UPI</option>
                                                <option value="Net Banking">By Net Banking</option>
                                            </Form.Select>
                                        </Col>
                                        <Col md={6} className={`${display}`}>
                                            <h6 className='text-blue fw-6'>Cheque/Draft No <span className='text-danger'>*</span></h6>
                                            <input type="text" className='form-control mb-2' placeholder='By Cash/Cheque/Draft No' name="draftno" onChange={handleinput}  />
                                        </Col>
                                        <Col md={6} className={`${displayfrom}`}>
                                            <h6 className='text-blue fw-6'>From Bank<span className='text-danger'>*</span></h6>
                                            <input type="text" className='form-control mb-2' placeholder='Enter Bank Name' name="frombank" onChange={handleinput}  />
                                        </Col>
                                        <Col md={6} className={`${displayuip}`}>
                                            <h6 className='text-blue fw-6'>UPI Address<span className='text-danger'>*</span></h6>
                                            <input type="text" className='form-control mb-2' placeholder='Enter UPI Address' name="upi" onChange={handleinput}  />
                                        </Col>
                                        <Col md={6} className={`${displaypaydate}`}>
            
                                            <h6 className='text-blue fw-6' >{paymentdate}<span className='text-danger'>*</span></h6>
                                            <input type="date" className='form-control mb-2' placeholder='Enter Payment Date' name="paymentdate" onChange={handleinput}  />
                                        </Col>
                                        <Col md={6} className={`${displayat}`}>
                                           
                                            <h6 className='text-blue fw-6'>Drawn At Bank<span className='text-danger'>*</span></h6>
                                            <input type="text" className='form-control mb-2' placeholder='Drawn At Bank' name="bank" onChange={handleinput}  />
                                        </Col>
                                        <Col md={6}>
                                            <h6 className='text-blue fw-6' >Pending Balance If Any :</h6>
                                            <input type="number" className='form-control mb-2' placeholder='Enter Amount' name="pending_bal" onChange={handleinput} />
                                        </Col>
                                        <Col md={6} className={`${displaytobepaid}`}>
                                            <h6 className='text-blue fw-6' >To Be Paid On<span className='text-danger'>*</span></h6>
                                            <Form.Select className="rounded-4 mb-2" name="painon" onChange={handleinput} >
                                                <option value="" selected>Select Option</option>
                                                <option value="immediately">Immediately</option>
                                                <option value="ondate">Date</option>
                                            </Form.Select>
                                        </Col>
                                        <Col md={6} className={`${displaypaidon}`}>
                                            <h6 className='text-blue fw-6'>To Be Paid Date  <span className='text-danger'>*</span></h6>
                                            <input type="date" className='form-control  mb-2' placeholder='Enter Date' name="painondate" onChange={handleinput}  />
                                        </Col>
                                        <Col md={12}>
                                            <h6 className='text-blue fw-6'>
                                                Address Of Applicant <span className='text-danger'>*</span>
                                            </h6>
                                            <textarea
                                                className={`form-control mb-2 ${addressError ? 'border-danger' : ''}`}
                                                placeholder='Enter Address'
                                                name="address"
                                                onChange={handleinput}
                                                required
                                            ></textarea>
                                            {addressError && <span className="text-danger">{addressError}</span>}
                                        </Col>
                                        <Col md={12} >
                                            <h6 className='text-blue fw-6  '>Comments</h6>
                                            <div>
                                                {rows.map((row, index) => (
                                                    <div className="row" key={row.id}>
                                                        <div className="col-1">
                                                            <div className="border border-1 p-1 rounded-4 text-center">
                                                                <h5>{row.id}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-11">
                                                            
                                                            <input type="text" className='form-control mb-3' placeholder='Enter Comment' name={`comment_${row.id}`} onChange={handleinput} />
                                                        </div>
                                                      
                                                    </div>
                                                ))}

                                                <div className={`${displayrow}`}>
                                                    <button className="btn bg-blue text-white fw-6 " onClick={handleAddRow}>
                                                        Add More
                                                    </button>
                                                </div>
                                            </div>
                                        </Col> 

                                    </Row>
                                </Col>
                            </Row>

                        </form>
                    </Card>
                </div>
            </Container>
            <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="exampleModal" tabIndex="{-1}" aria-labelledby="exampleModalLabel" style={{ backdropFilter: 'blur(1px)', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content border-0 ">

                        <div class="modal-header border-bottom-1 border-1 border-dark mb-3">
                            <h2 class="modal-title">Payment Invoice</h2>
                            <button type="button" class="btn-close"  onClick={onClose}></button>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" onClick={onClose}>
                                Close
                            </button>
                            <button type="button" className="btn btn-primary" onClick={generatePDF2}>
                                Save As Pdf
                            </button>
                            <button type="button" className="btn btn-danger" onClick={generatePDF3}>
                                Print
                            </button>
                        </div>
                        <div id='targetLR' className='mx-3 mb-4'>
                            <Invoice ref={invoiceRef} id={invoiceid} />
                        </div>
                  
                    </div>
                </div>
            </div>
 
        </div>
    )
}

export default User;